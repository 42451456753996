import {
  AgentConnectionWithMerchant,
  getEquipmentSchema,
  getWebProgramEquipmentValidationObject,
} from '@ozark/common';
import * as yup from 'yup';

export const getProgramEquipmentSchema = ({cashDiscountCap}: {cashDiscountCap?: number} = {}) => {
  const programEquipmentValidationCommonObject =
    getWebProgramEquipmentValidationObject(cashDiscountCap);
  return yup.object().shape({
    ...programEquipmentValidationCommonObject,
    agentId: yup.string().required('Agent assignment is required'),
    agentConnectionWithMerchant: yup
      .string()
      .required('How did you connect with this merchant information is required')
      .oneOf(Object.values(AgentConnectionWithMerchant)),
    equipment: getEquipmentSchema({cashDiscountCap, useCustomErrorMessage: true}).required(),
    equipmentAdditional: yup
      .array()
      .of(getEquipmentSchema({cashDiscountCap, useCustomErrorMessage: true}))
      .ensure(),
  });
};
