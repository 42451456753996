import Hidden from '@mui/material/Hidden';
import makeStyles from '@mui/styles/makeStyles';
import {
  Application as ApplicationType,
  ApplicationTemplateView,
  PdfDocuments,
  TransferIntent,
  useApiClient,
  useKeyPress,
  useNotification,
} from '@ozark/common';
import {Copyright, Loading, TransferToMerchantConfirmationDialogEx} from '@ozark/common/components';
import {useCallback, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {useCallable} from '../../firebase/hooks/useCallable';
import {useStore} from '../../store';
import {isAgentOrErpUser} from '../../util/authUserUtils';
import Actions from '../Actions';
import BasicsPage from '../BasicsPage';
import BasicsPageForAgents from '../BasicsPageForAgents';
import BusinessPage from '../BusinessPage';
import BusinessPageForAgents from '../BusinessPageForAgents';
import DepositsPage from '../DepositsPage';
import DepositsPageForAgents from '../DepositsPageForAgents';
import InstandIdQaPage from '../InstantIdQaPage';
import MobileActions from '../MobileActions';
import ProgramPage from '../ProgramPage';
import ProgramPageForAgents from '../ProgramPageForAgents';
import ProgramReviewPage from '../ProgramReviewPage';
import ReviewPage from '../ReviewPage';
import ReviewPageForAgents from '../ReviewPageForAgents';
import SignerPage from '../SignerPage';
import SignerPageForAgents from '../SignerPageForAgents';
import StartPageAnonymous from '../StartPage';
import StartPage from '../StartWithRegistrationPage';
import Stepper from '../Stepper';
import {ApplicationFormHeader} from './components/ApplicationFormHeader';
import {PortalLoginAlert} from './components/PortalLoginAlert';
import {TestModeAlert} from './components/TestModeAlert';
import {
  useApplicationFormManager,
  useApplicationFromRouteId,
  useApplicationSteps,
  useAutoCreatingApplication,
  useEnableRegistration,
  useSessionProgress,
  useTestApplicationData,
} from './hooks';
import {useApplicationQuery} from './hooks/useApplicationQuery';
import {useApplicationRedirectToSummary} from './hooks/useApplicationRedirectToSummary';

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  root: {
    marginTop: spacing(4),
    marginBottom: spacing(4),
    marginLeft: '10%',
    marginRight: '10%',
    [breakpoints.down('md')]: {
      marginLeft: '5%',
      marginRight: '5%',
    },
    [breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: 0,
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(1),
      marginBottom: 72,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  page: {
    marginTop: spacing(2),
    marginBottom: spacing(4),
    [breakpoints.down('sm')]: {
      marginLeft: spacing(2),
      marginTop: spacing(4),
      marginRight: spacing(2),
      marginBottom: spacing(17),
    },
    [breakpoints.down(560)]: {
      marginBottom: spacing(20),
    },
    [breakpoints.down(490)]: {
      marginBottom: spacing(21),
    },
    [breakpoints.down(411)]: {
      marginBottom: spacing(24),
    },
    [breakpoints.down(350)]: {
      marginBottom: spacing(29),
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: spacing(2),
    marginTop: spacing(2),
  },
  alert: {
    marginBottom: spacing(4),
    [breakpoints.down('sm')]: {
      marginTop: spacing(4),
    },
    [breakpoints.down('xs')]: {
      marginTop: spacing(0),
    },
  },
}));

const Application = () => {
  const showNotification = useNotification();
  const {application, applicationId} = useApplicationFromRouteId();
  const classes = useStyles();
  const history = useHistory<{distinguishableId: string; queryParams: {[key: string]: string}}>();
  const [enter, enterEvent] = useKeyPress('Enter');
  const [onSaveTemplateClickHandler, setOnSaveTemplateClickHandler] = useState<() => () => void>();
  const [transferToMerchantDialogOpen, setTransferToMerchantDialogOpen] = useState<boolean>(false);

  const store = useStore();
  const {authUser, group, signOut, onSignOut} = store;
  const isEnableOnlineApplicationLink =
    group.data?.applicationSettings?.enableOnlineApplicationLink;
  const {downloadPdf} = useCallable();
  const {apiClient} = useApiClient(authUser);
  const {enableRegistration} = useEnableRegistration();

  const isApplicationFormForAgentOrErpUser = isAgentOrErpUser(store);

  const {saveCurrentProgressWithoutValidation, handleEndSession, setEndSessionHandler} =
    useSessionProgress();
  const {activeStep, steps, goToPreviousStep, goToNextStep, isLastStep, isVerifyStep} =
    useApplicationSteps(applicationId, isApplicationFormForAgentOrErpUser);
  const {populateTestDataToApplication} = useTestApplicationData(applicationId, activeStep);
  const [loadedApplicationTemplate, setLoadedApplicationTemplate] =
    useState<ApplicationTemplateView>();

  const openTransferToMerchantDialog = () => {
    setTransferToMerchantDialogOpen(true);
  };

  const {
    setValidationHandler,
    validationHandler,
    handleError,
    handleSuccess,
    email,
    setEmail,
    loading,
    setLoading,
    registration,
    clearSessionAndAdvanceToFinalPage,
    saveAppWithoutAdvance,
  } = useApplicationFormManager(
    isApplicationFormForAgentOrErpUser,
    enableRegistration,
    activeStep,
    steps,
    goToNextStep
  );
  const {isApplicationStarting} = useAutoCreatingApplication(
    applicationId,
    registration,
    email,
    setEmail
  );
  const {isSummary} = useApplicationQuery();
  const {redirectToSummary} = useApplicationRedirectToSummary();

  const location = useLocation<{
    applicationData?: Pick<
      ApplicationType,
      'firstName' | 'lastName' | 'legalBusinessName' | 'doingBusinessAs' | 'distinguishableId'
    >;
    isTransferredApplication: boolean;
  }>();

  useEffect(() => {
    if (application.promised) return;
    let isMounted = true;

    const unsubscribe = onSignOut(async () => {
      if (!isMounted) {
        return;
      }

      console.log('sign out event executing');
      history.push('/');
    });
    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, [application.promised, history, onSignOut]);

  const {isTransferredApplication} = location.state ?? {isTransferredApplication: false};

  const handleDownloadPdf = useCallback(async () => {
    setLoading(true);
    downloadPdf({
      applicationId: applicationId,
      pdfDocument: isTransferredApplication
        ? PdfDocuments.EvolveTSYS
        : PdfDocuments.EvolveTSYSNoLogo,
      fileName: `evolve-${applicationId}.pdf`,
    })
      .then((result: any) => {
        if (result.status === 'error' || !result.downloadUrl) {
          console.error('pdfDownloadError', result);
          showNotification('error', 'Failed to download PDF');
          return;
        }
        const a = document.createElement('a');
        a.href = result.downloadUrl;
        a.setAttribute('target', '_blank');
        a.setAttribute('download', `evolve-${applicationId}.pdf`);
        a.click();
      })
      .catch((error: any) => {
        console.error('pdfDownloadError', error);
        showNotification('error', 'Failed to download PDF');
        return;
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId, downloadPdf]);

  const handleBack = async () => {
    if (isSummary) {
      redirectToSummary(applicationId);
      return;
    }

    activeStep.back ? goToPreviousStep() : await signOut();
  };

  const handleNext = async () => {
    if (!validationHandler || typeof validationHandler !== 'function') {
      return;
    }
    setLoading(true);
    await validationHandler(handleSuccess, handleError)();
  };

  const handleSaveProgress = useCallback(async () => {
    if (!validationHandler || typeof validationHandler !== 'function') {
      return;
    }
    setLoading(true);
    await validationHandler(async (data: any) => {
      try {
        if (await saveAppWithoutAdvance(data)) {
          await handleEndSession();
        }
      } finally {
        setLoading(false);
      }
    }, handleError)();
  }, [handleEndSession, handleError, saveAppWithoutAdvance, setLoading, validationHandler]);

  const onTransferToMerchantClickHandler = useCallback(async () => {
    if (!application.data || !isApplicationFormForAgentOrErpUser) {
      return;
    }
    if (activeStep.index === 1) {
      showNotification(
        'info',
        '"Transfer to Merchant" functionality is available on next page. Please fill out all required fields on the current page.'
      );
      return;
    }
    openTransferToMerchantDialog();
  }, [activeStep?.index, application.data, isApplicationFormForAgentOrErpUser, showNotification]);

  useEffect(() => {
    // this logic allows to go to the next step when the user presses Enter on the current step.
    if (enter && enterEvent.target.tagName === 'INPUT') {
      handleNext();
    }
  }, [enter]); // eslint-disable-line react-hooks/exhaustive-deps

  const moveToTransferConfirmationPage = () => {
    history.push(ROUTES.TRANSFERRED);
  };

  // show the loading indicator until user and application promises resolve
  if (authUser.promised || application.promised || !activeStep || isApplicationStarting) {
    return <Loading />;
  }

  if (!authUser.promised && authUser.data?.email && !applicationId) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <ApplicationFormHeader
        loading={loading}
        onSaveTemplate={onSaveTemplateClickHandler}
        onTransferToMerchant={
          isApplicationFormForAgentOrErpUser ? onTransferToMerchantClickHandler : undefined
        }
        onSignOutClick={signOut}
        onSaveProgress={async () => {
          await handleSaveProgress();
        }}
        onDownloadPdf={
          applicationId && isApplicationFormForAgentOrErpUser ? handleDownloadPdf : undefined
        }
        activeStep={activeStep}
      />
      {isApplicationFormForAgentOrErpUser && (
        <PortalLoginAlert className={classes.alert} userEmail={authUser.data?.email} />
      )}

      {['ozark-staging', 'getevolved-staging'].includes(
        process.env.REACT_APP_FIREBASE_PROJECT_ID ?? ''
      ) &&
        authUser.data &&
        application.data?.id && (
          <TestModeAlert
            className={classes.alert}
            onPopulateTestDataClick={populateTestDataToApplication}
          />
        )}
      <Hidden smDown>
        <Stepper applicationId={applicationId} steps={steps} activeStep={activeStep} />
      </Hidden>
      <div className={classes.page}>
        <Switch>
          <Route
            path={ROUTES.START}
            render={() => {
              if (!isEnableOnlineApplicationLink) {
                return <Redirect to={ROUTES.NOT_AVAILABLE} />;
              }
              return enableRegistration ? (
                <StartPage setValidationHandler={setValidationHandler} />
              ) : (
                <StartPageAnonymous setValidationHandler={setValidationHandler} />
              );
            }}
          />
          {!authUser?.data && <Redirect to={ROUTES.START} />}
          <Route
            path={ROUTES.BASICS}
            render={() =>
              isApplicationFormForAgentOrErpUser ? (
                <BasicsPageForAgents
                  setValidationHandler={setValidationHandler}
                  setEndSessionHandler={setEndSessionHandler}
                />
              ) : (
                <BasicsPage setValidationHandler={setValidationHandler} />
              )
            }
          />
          <Route
            path={ROUTES.BUSINESS}
            render={() =>
              isApplicationFormForAgentOrErpUser ? (
                <BusinessPageForAgents
                  setValidationHandler={setValidationHandler}
                  setEndSessionHandler={setEndSessionHandler}
                />
              ) : (
                <BusinessPage setValidationHandler={setValidationHandler} />
              )
            }
          />
          <Route
            path={ROUTES.SIGNER}
            render={() =>
              isApplicationFormForAgentOrErpUser ? (
                <SignerPageForAgents
                  setValidationHandler={setValidationHandler}
                  setEndSessionHandler={setEndSessionHandler}
                />
              ) : (
                <SignerPage setValidationHandler={setValidationHandler} />
              )
            }
          />
          <Route
            path={ROUTES.DEPOSITS}
            render={() =>
              isApplicationFormForAgentOrErpUser ? (
                <DepositsPageForAgents
                  setValidationHandler={setValidationHandler}
                  setEndSessionHandler={setEndSessionHandler}
                />
              ) : (
                <DepositsPage setValidationHandler={setValidationHandler} setLoading={setLoading} />
              )
            }
          />
          <Route
            path={ROUTES.PROGRAM}
            render={() => {
              const {transferIntent, isPortalApplication} = authUser.claims ?? {};
              if (transferIntent === TransferIntent.transferApplication && !!isPortalApplication) {
                return <ProgramReviewPage setValidationHandler={setValidationHandler} />;
              }

              if (isApplicationFormForAgentOrErpUser) {
                return (
                  <ProgramPageForAgents
                    setValidationHandler={setValidationHandler}
                    setEndSessionHandler={setEndSessionHandler}
                    setOnSaveTemplateClickHandler={setOnSaveTemplateClickHandler}
                    loadedApplicationTemplate={loadedApplicationTemplate}
                    setLoadedApplicationTemplate={setLoadedApplicationTemplate}
                  />
                );
              }

              return <ProgramPage setValidationHandler={setValidationHandler} />;
            }}
          />
          <Route
            path={ROUTES.REVIEW}
            render={() =>
              isApplicationFormForAgentOrErpUser ? (
                <ReviewPageForAgents setValidationHandler={setValidationHandler} />
              ) : (
                <ReviewPage setValidationHandler={setValidationHandler} />
              )
            }
          />
          <Route
            path={ROUTES.INSTANT_ID_Q_AND_A}
            render={() => (
              <InstandIdQaPage
                applicationId={applicationId}
                setValidationHandler={setValidationHandler}
                externalServicesApi={apiClient.externalServices}
                clearSessionAndAdvanceToFinalPage={clearSessionAndAdvanceToFinalPage}
              />
            )}
          />
        </Switch>
      </div>
      <Hidden smDown>
        <Actions
          activeStep={activeStep}
          onBack={handleBack}
          onNext={handleNext}
          onSaveTemplate={onSaveTemplateClickHandler}
          loading={loading}
          isBackButtonHidden={enableRegistration && activeStep?.index === 0}
          isApplicationFormForAgentOrErpUser={isApplicationFormForAgentOrErpUser}
          isLastStep={isLastStep}
          isVerifyStep={isVerifyStep}
        />
        <footer className={classes.footer}>
          {!!group.data && (
            <Copyright
              groupName={group.data.name}
              groupDomain={`${window.location.protocol}//${group.data.appDomain}`}
            />
          )}
        </footer>
      </Hidden>
      <Hidden smUp>
        <MobileActions
          isApplicationFormForAgentOrErpUser={isApplicationFormForAgentOrErpUser}
          isLastStep={isLastStep}
          isVerifyStep={isVerifyStep}
          steps={steps}
          activeStep={activeStep}
          onBack={handleBack}
          onNext={handleNext}
          loading={loading}
        />
      </Hidden>
      {transferToMerchantDialogOpen && (
        <TransferToMerchantConfirmationDialogEx
          applicationId={application.data.id}
          onClose={() => setTransferToMerchantDialogOpen(false)}
          onBeforeTransfer={saveCurrentProgressWithoutValidation}
          onSuccess={moveToTransferConfirmationPage}
        />
      )}
    </div>
  );
};

export default Application;
